$ ->
  console.log ''
class EditableTextarea
  @unselectedInsert: (html, node) ->
    el = document.createElement('div')
    el.innerHTML = html
    frag = document.createDocumentFragment()
    while node = el.firstChild
      lastNode = frag.appendChild(node)
    document.getElementById('editable-textarea-dummy').prependChild(frag)

  @restoreSelection: (range) ->
    if range
      if window.getSelection
        sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange range
      else if document.selection and range.select
        range.select()
    return

  @pasteHtmlAtCaret: (html, insertSavedRange) ->
    EditableTextarea.restoreSelection(insertSavedRange)
    Element::prependChild = (el) ->
      @insertBefore el, @firstChild
      return
    sel = undefined
    range = undefined
    node = undefined
    lastNode = undefined
    if window.getSelection
      # IE9 and non-IE
      sel = window.getSelection()
      if sel.getRangeAt and sel.rangeCount
        range = sel.getRangeAt(0)
        range.deleteContents()
        el = document.createElement('div')
        el.innerHTML = html
        frag = document.createDocumentFragment()
        while node = el.firstChild
          lastNode = frag.appendChild(node)
        range.insertNode frag
        # Preserve the selection
        if lastNode
          range = range.cloneRange()
          range.setStartAfter lastNode
          range.collapse true
          sel.removeAllRanges()
          sel.addRange range
      else
        EditableTextarea.unselectedInsert(html, node)
    else if document.selection and document.selection.type != 'Control'
      # IE < 9
      document.selection.createRange().pasteHTML html
    return

$ ->
  if $('#editable-textarea-dummy').get(0)
    savedRange = undefined
    $('#editable-textarea-dummy').on 'click focus keypress', ->
      if window.getSelection
        saveSel = window.getSelection()
        if saveSel.getRangeAt and saveSel.rangeCount
          savedRange = saveSel.getRangeAt(0)

    $('.editable-textarea-action-btns button.image-upload').on 'click', (e) ->
      e.preventDefault()
      $("form.form-upload-image input[type='file']").trigger('click')
    $('form.form-upload-image')
      .on 'change', "input[type=file]", ->
        $('form.form-upload-image').find('input[type=submit]').click()
      .on 'ajax:beforeSend', ->
        $('.image-uploading').removeClass('hide')
      .on 'ajax:success', (event) ->
        [data, _status, xhr] = event.detail
        EditableTextarea.pasteHtmlAtCaret(data.html, savedRange)
        $('.image-uploading').addClass('hide')

    $('.editable-textarea-action-btns button.bold').on 'click', (e) ->
      e.preventDefault()
      document.execCommand 'bold', false, 'strong'
    $('.editable-textarea-action-btns button.color-black').on 'click', (e) ->
      e.preventDefault()
      document.execCommand 'forecolor', false, '#333'
    $('.editable-textarea-action-btns button.color-red').on 'click', (e) ->
      e.preventDefault()
      document.execCommand 'forecolor', false, '#ff3232'
    $('.editable-textarea-action-btns button.color-blue').on 'click', (e) ->
      e.preventDefault()
      document.execCommand 'forecolor', false, '#4444e5'
    $('.editable-textarea-action-btns button.bg-color-yellow').on 'click', (e) ->
      e.preventDefault()
      document.execCommand 'backColor', false, '#ffff99'
    $('.editable-textarea-action-btns button.style-remove').on 'click', (e) ->
      e.preventDefault()
      document.execCommand 'removeFormat', false, ''
    $('.editable-textarea-action-btns button.fs').on 'click', (e) ->
      e.preventDefault()
      return if document.getSelection()['focusNode']['data'] is undefined
      document.execCommand('insertHTML', false, "<p class=#{$(@).data('fs')}>#{document.getSelection()}</p>")

    $('.form-editable-textarea-insert-link').on 'click', 'button', (e) ->
      e.preventDefault()
      text = $('.form-editable-textarea-insert-link .editable-textarea-link-text').val()
      url = $('.form-editable-textarea-insert-link .editable-textarea-link-url').val()
      if text isnt '' || url isnt ''
        EditableTextarea.pasteHtmlAtCaret("<a href='#{url}' target='_blank'>#{text}</a>", savedRange)
        $(@).parents('.dropdown-menu').removeClass('show-menu')

    $('.form-editable-textarea-insert-video').on 'click', 'button', (e) ->
      e.preventDefault()
      url = $('.form-editable-textarea-insert-video .editable-textarea-video-url').val()
      if url isnt ''
        EditableTextarea.pasteHtmlAtCaret(url, savedRange)
        $(@).parents('.dropdown-menu').removeClass('show-menu')

    $('.form-editable-textarea-insert-ads').on 'click', 'button', (e) ->
      e.preventDefault()
      url = "<span class='ads'>" + $('.form-editable-textarea-insert-ads .editable-textarea-ads-url').val() + "</span>"
      if url isnt ''
        EditableTextarea.pasteHtmlAtCaret(url, savedRange)
        $(@).parents('.dropdown-menu').removeClass('show-menu')

    element = document.getElementById('editable-textarea-dummy')
    element.addEventListener 'keypress', (e) ->
      if e.which is 13 || e.keyCode is 13
        document.execCommand 'formatBlock', false, 'p'
    element.addEventListener 'paste', (e) ->
      e.preventDefault()
      text = e.clipboardData.getData('text/plain')
      if text.length isnt 0
        document.execCommand 'insertText', false, text

    $('#editable-textarea-dummy')
      .on 'click blur focusout keypress', ->
        if $('#editable-textarea-dummy img.ui-resizable').get(0)
          $('#editable-textarea-dummy img.ui-resizable').resizable('destroy')
        $('.editable-textarea textarea').val($(@).html())

    $(document)
      .on 'click', '#editable-textarea-dummy img', (e) ->
        e.preventDefault()
        imgWidth  = $(@).width()
        imgHeight = $(@).height()
        $(@).resizable
          aspectRatio: $(@).width() / $(@).height()
          minWidth: 100
          maxWidth: $('#editable-textarea-dummy').width()
        $(@).parents('.ui-wrapper').css({ 'width' : "#{imgWidth}px" })
        $(@).parents('.ui-wrapper').css({ 'height' : "#{imgHeight}px" })
        $(@).css({ 'width' : "#{imgWidth}px" })
        $(@).css({ 'height' : "#{imgHeight}px" })
